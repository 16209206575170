.app-class {
  padding: 0 !important;
}

#root {
  overflow: hidden;
}

.test>div{
  height:100vh;
}
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&family=Raleway:wght@200;300;400;700&display=swap);
:root{
  --turchese:#C8E0E8;
  --pesca:#F7B6B6;
  --verde:#D8EACC;
  --giallo:#F6DA8D;
  --azzurro:#13EBF6;
  --blue:#3C4858;
  --white: #ffffff;
}
/* font-family: 'Fredoka One', cursive;
font-family: 'Raleway', sans-serif; */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiCardHeader-avatar{
  margin-right: 0!important;
}

.loaderContainer{
  position:fixed;
  z-index:100000;
  top:0;
  left: 0;
  width:100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(255,255,255,0.9);
  -webkit-flex-direction: column;
          flex-direction: column;
}
.loaderContainer h3{
  text-align: center;
  clear: both;
}
.loaderContainer img {
  width: 10vw;
  height: 10vw;
  -webkit-transform-origin: 50% 80%;
  -webkit-transform: rotate(-15deg);
  transform-origin: 50% 80%;
  transform: rotate(-15deg);
  -webkit-animation: splash-logo 1.5s ease-out infinite;
          animation: splash-logo 1.5s ease-out infinite;
}

.logoLoader {
  -webkit-transform-origin: 50% 80%;
  -webkit-transform: rotate(-15deg);
  transform-origin: 50% 80%;
  transform: rotate(-15deg);
  -webkit-animation: splash-logo 1.5s ease-out infinite;
          animation: splash-logo 1.5s ease-out infinite;
}

@-webkit-keyframes splash-logo {
  25% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
  45% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(-15deg);
    transform-origin: 50% 80%;
    transform: rotate(-15deg);
  }
  75% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
}

@keyframes splash-logo {
  25% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
  45% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(-15deg);
    transform-origin: 50% 80%;
    transform: rotate(-15deg);
  }
  75% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
}
/*===========NEW STYLE===========*/
/*===========GENERAL===========*/
.filterRoot{
  margin-bottom:30px!important;
}

.filterRoot > div{
  margin-bottom:20px;
}

.buttonUpload{
  background-color: #3C4858!important;
  background-color: var(--blue)!important;
  color:white!important;
  padding:15px!important;
}

.filterRoot > div .MuiPaper-root{
  padding:0;
}

.searchField{
  margin-top:15px!important;
  margin-left:0!important;
}

.userIcon{
  opacity:1;
  transition: all 0.2s ease-out!important;
}

.fade-out{
  opacity:0;
  transition: all 0.2s ease-out!important;
}


h1,h2,.MuiListItemText-root .MuiTypography-root,.snippetCard h5, #root h5, .siteName{
  font-family: 'Fredoka One', cursive!important;
}

h5{
  color:#3C4858!important;
  color:var(--blue)!important;
  font-size: 1.5rem!important;
}

.MuiListItemText-root{
  color:#3C4858;
  color:var(--blue);
}

.MuiListItem-root.Mui-selected .MuiListItemText-root{
  color:white;
}

p, ul, li, h6, td, th, input, textarea, label, span.MuiTab-wrapper{
  font-family: 'Raleway', sans-serif!important;
}

.logoLoaderContainer{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.logoLoaderContainer h3{
  font-family: 'Raleway', sans-serif!important;
  color:#3C4858;
  color:var(--blue);
  font-weight: normal;
}
th{
  font-weight: bold!important;
}
.MuiPaper-elevation1{
  box-shadow: none!important;
}
.sourceLogo{
  margin:30px 0;
  border-radius:50%;
  border: 5px solid #fff;
}
.snippetCard{
  padding:15px!important;
  border-radius:10px;
  position: relative;
}
.boxContainer a >div{
  box-shadow:0 0 2px #ABABAB!important;
  border-radius: 10px;
}
.innerCard{
  box-shadow:0 0 2px #ABABAB;
  border-radius: 10px;
  padding:0px;
  margin:10px;
  overflow: hidden;
  position: relative;
}
.bottomCard{
  width:100%;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.editIcon{
  position:absolute;
  right:20px;
  top:20px;
  z-index: 10;
  cursor:pointer;
}
.borderFrame{
  width:100%;
  display:-webkit-flex;
  display:flex;
  -webkit-align-items:center;
          align-items:center;
  -webkit-justify-content:center;
          justify-content:center;
  border:5px solid #ABABAB;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
}
.MuiCardActions-spacing .MuiIconButton-label{
  color:#3C4858;
  color:var(--blue);
}
.avatarPl{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 2rem;
}
.MuiButton-textPrimary{
  color:#888!important;
}
.MuiButton-textSecondary{
  color:black!important;
  font-weight: bold!important;
}
/*----------Modal----------*/
.MuiDialogTitle-root{
  background-color: #3C4858;
  background-color: var(--blue);
}
.MuiDialogTitle-root h2{
  color:#3C4858;
  color:var(--blue);
}
.MuiDialogTitle-root button{
  color:white;
}
.MuiTabs-indicator{
  background-color: #3C4858;
  background-color: var(--blue);
}
.changePassword .MuiDialogTitle-root h2{
  color:white!important;
}
/*----------Form----------*/
.MuiInputBase-input{
  padding:10px!important;
}
.MuiInput-underline.Mui-disabled:before{
  border-bottom:3px solid #EAEAEA!important;
  margin-top:5px;
}
.MuiInput-root{
  font-family: 'Raleway', sans-serif!important;
}
.MuiInput-underline:before{
  border-bottom:3px solid #AEAEAE!important;
}
.MuiInput-underline:after{
  border-bottom:3px solid #13EBF6!important;
  border-bottom:3px solid var(--azzurro)!important;
}
.MuiFormLabel-root.Mui-focused{
  color:#13EBF6!important;
  color:var(--azzurro)!important;
}

.customSidebar{
  border-right:5px solid #3C4858;
  border-right:5px solid var(--blue);
}

#customMobileSidebar .MuiDrawer-paper{
  border-right:5px solid #3C4858;
  border-right:5px solid var(--blue);
}
/*----------Modal----------*/
.helpModal .MuiDialogTitle-root h2{
  color:white;
}
.helpModal .MuiTabs-indicator{
  background-color: #3C4858!important;
  background-color: var(--blue)!important;
}
/*===========COMPANIES===========*/
#root.companies h1{
  color:#C8E0E8!important;
  color:var(--turchese)!important;
}
#root.companies .customSidebar .Mui-selected{
  background-color: #C8E0E8!important;
  background-color: var(--turchese)!important;
}
#root.companies .customSidebar{
  border-right:5px solid #C8E0E8;
  border-right:5px solid var(--turchese);
}
#root.companies .MuiDivider-root{
  background-color:#C8E0E8;
  background-color:var(--turchese);
}
#root.companies .bottomCard{
  background-color:#C8E0E8;
  background-color:var(--turchese);
}
#root.companies .sourceLogo{
  border-color: #C8E0E8;
  border-color: var(--turchese);
}
#root.companies .borderFrame{
  border-color:#C8E0E8;
  border-color:var(--turchese);
}
#root.companies .MuiButton-textSecondary{
  color:#C8E0E8!important;
  color:var(--turchese)!important;
}
.companies-box .icona{
  background:#C8E0E8;
  background:var(--turchese);
}
/*----------Mobile sidebar---------*/
body.companies #customMobileSidebar .Mui-selected{
  background-color: #C8E0E8!important;
  background-color: var(--turchese)!important;
}
body.companies #customMobileSidebar .MuiDrawer-paper{
  border-right:5px solid #C8E0E8!important;
  border-right:5px solid var(--turchese)!important;
}
/*----------Modal----------*/
.companyModal .MuiDialogTitle-root{
  background-color: #C8E0E8!important;
  background-color: var(--turchese)!important;
}
.companyModal .MuiTabs-indicator{
  background-color: #C8E0E8;
  background-color: var(--turchese);
}
/*----------MailModalSupplychain----------*/
.mailModal .MuiDialogTitle-root{
  background-color: #ffffff!important;
  background-color: var(--white)!important;
}
/*----------CreateModalSupplychain----------*/
.createModalSupplychain .MuiDialogTitle-root{
  background-color: #ffffff!important;
  background-color: var(--white)!important;
}
/*===========FACILITIES===========*/
#root.facilities h1{
  color:#F7B6B6!important;
  color:var(--pesca)!important;
}
#root.facilities .customSidebar .Mui-selected{
  background-color: #F7B6B6!important;
  background-color: var(--pesca)!important;
}
#root.facilities .customSidebar{
  border-right:5px solid #F7B6B6;
  border-right:5px solid var(--pesca);
}
#root.facilities .MuiDivider-root{
  background-color:#F7B6B6;
  background-color:var(--pesca);
}
#root.facilities .bottomCard{
  border:5px solid #F7B6B6;
  border:5px solid var(--pesca);
  border-radius: 10px;
  min-height: 150px;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  position:relative;
}
#root.facilities .bottomCard > .MuiButtonBase-root{
  position: absolute;
  height: 100%;
}
#root.facilities .sourceLogo{
  border-color: #F7B6B6;
  border-color: var(--pesca);
}
#root.facilities .borderFrame{
  border-color:#F7B6B6;
  border-color:var(--pesca);
}
#root.facilities .MuiButton-textSecondary{
  color:#F7B6B6!important;
  color:var(--pesca)!important;
}
.facilities-box .icona{
  background:#F7B6B6;
  background:var(--pesca);
}
/*----------Mobile sidebar---------*/
body.facilities #customMobileSidebar .Mui-selected{
  background-color: #F7B6B6!important;
  background-color: var(--pesca)!important;
}
body.facilities #customMobileSidebar .MuiDrawer-paper{
  border-right:5px solid #F7B6B6!important;
  border-right:5px solid var(--pesca)!important;
}
/*----------Modal----------*/
.facilityModal .MuiDialogTitle-root{
  background-color: #F7B6B6;
  background-color: var(--pesca);
}
.facilityModal .MuiTabs-indicator{
  background-color: #F7B6B6;
  background-color: var(--pesca);
}
/*===========PRODUCTS===========*/
#root.products .MuiAvatar-root{
  margin:30px 0;
  border-radius:50%;
  border: 5px solid #D8EACC;
  border: 5px solid var(--verde);
  width:auto;
  height: auto;
}
#root.products h1{
  color:#D8EACC!important;
  color:var(--verde)!important;
}
#root.products .customSidebar .Mui-selected{
  background-color: #D8EACC!important;
  background-color: var(--verde)!important;
}
#root.products .customSidebar{
  border-right:5px solid #D8EACC;
  border-right:5px solid var(--verde);
}
#root.products .MuiDivider-root{
  background-color:#D8EACC;
  background-color:var(--verde);
}
#root.products .bottomCard{
  background-color:#D8EACC;
  background-color:var(--verde);
}
#root.products .sourceLogo{
  border-color: #D8EACC;
  border-color: var(--verde);
}
#root.products .borderFrame{
  border-color:#D8EACC;
  border-color:var(--verde);
}
#root.products .MuiButton-textSecondary{
  color:#D8EACC!important;
  color:var(--verde)!important;
}
.products-box .icona{
  background:#D8EACC;
  background:var(--verde);
}
/*----------Mobile sidebar---------*/
body.products #customMobileSidebar .Mui-selected{
  background-color: #D8EACC!important;
  background-color: var(--verde)!important;
}
body.products #customMobileSidebar .MuiDrawer-paper{
  border-right:5px solid #D8EACC!important;
  border-right:5px solid var(--verde)!important;
}
/*----------Modal----------*/
.productModal .MuiDialogTitle-root{
  background-color: #D8EACC;
  background-color: var(--verde);
}
.productModal .MuiTabs-indicator{
  background-color: #D8EACC;
  background-color: var(--verde);
}
/*===========LOTS===========*/
#root.lots .bottomCard{
  border:5px solid #F6DA8D;
  border:5px solid var(--giallo);
  border-radius: 10px;
  min-height: 150px;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  position:relative;
}
#root.lots .bottomCard > .MuiButtonBase-root{
  position: absolute;
  height: 100%;
}
#root.lots .sourceLogo{
  border-color: #F6DA8D;
  border-color: var(--giallo);
}
#root.lots .MuiButton-textSecondary{
  color:#F6DA8D!important;
  color:var(--giallo)!important;
}

#root.lots h1{
  color:#F6DA8D!important;
  color:var(--giallo)!important;
}
#root.lots .customSidebar .Mui-selected{
  background-color: #F6DA8D!important;
  background-color: var(--giallo)!important;
}
#root.lots .customSidebar{
  border-right:5px solid #F6DA8D;
  border-right:5px solid var(--giallo);
}
#root.lots .MuiDivider-root{
  background-color:#FFF;
}

#root.lots .sourceLogo{
  border-color: #F6DA8D;
  border-color: var(--giallo);
}
#root.lots .borderFrame{
  border-color:#F6DA8D;
  border-color:var(--giallo);
}

#root.lots .MuiCardHeader-content .MuiCardHeader-title,#root.lots h6{
  font-family: 'Fredoka One', cursive!important;
  color:#3C4858!important;
  color:var(--blue)!important;
}

#root.lots .MuiCardHeader-content .MuiCardHeader-subheader{
  font-family: 'Raleway', sans-serif!important;
}

#root.lots .MuiButton-textSecondary{
  color:#F6DA8D!important;
  color:var(--giallo)!important;
}

#root.lots .bottomCard {
  -webkit-align-items:flex-end;
          align-items:flex-end;
}

#root.lots .bottomCard .MuiCardActions-root{
  width:100%;
  -webkit-align-items:center;
          align-items:center;
} 

#root.lots .bottomCard .MuiCardActions-root .MuiGrid-container{
  display:-webkit-flex;
  display:flex;
  -webkit-align-items:center;
          align-items:center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top:40px;
}

.draftLabel{
  background:#eaeaea;
  padding:5px;
  border-radius:5px;
}

.lots-box .icona{
  background:#F6DA8D;
  background:var(--giallo);
}
/*----------Mobile sidebar---------*/
body.lots #customMobileSidebar .Mui-selected{
  background-color: #F6DA8D!important;
  background-color: var(--giallo)!important;
}
body.lots #customMobileSidebar .MuiDrawer-paper{
  border-right:5px solid #F6DA8D!important;
  border-right:5px solid var(--giallo)!important;
}
/*----------Modal----------*/
.lotModal .MuiDialogTitle-root{
  background-color: #F6DA8D;
  background-color: var(--giallo);
}
.lotModal .MuiTabs-indicator{
  background-color: #F6DA8D;
  background-color: var(--giallo);
}
/*===========ANALYTICS===========*/
.analyticsTable .MuiTypography-h6{
  font-family: 'Fredoka One', cursive!important;
  color:#3C4858;
  color:var(--blue);
}
/*===========SIGN IN | SIGN UP===========*/
.signContainer{
  padding:15px;
  background-color: #FAFAFA!important;
  border-radius: 10px;
}
.signContainer a,.signContainer a:link,.signContainer a:active, .signContainer a:visited{
  color:#3C4858;
  color:var(--blue);
}
.signContainer a:hover{
  color:#13EBF6;
  color:var(--azzurro);
}
.signContainer input{
  text-align: center;
}
.signInHeader{
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  margin:20px 0;
}
.signContainer *{
  font-family: 'Raleway', sans-serif!important;
}
.signContainer .MuiCardContent-root{
  margin:25px 0;
}
.brand{
  max-height: 50px;
  margin-top:20px;
}
.logoContainer{
  background-size: contain!important;
  max-height: 50px;
  margin-top:20px;
}
.logoContainerCustom{
  background-size: contain!important;
  margin-top:20px;
}
.userIcon{
  background-color:#3C4858!important;
  background-color:var(--blue)!important;
}
.passwordReset .Dashboard-pageContainer-11{
  height: calc(100vh - 48px);
}
.passwordReset  .MuiGrid-container {
  height: 100%;
}

@media screen and (max-width:960px){
  .customSidebar{
    border-right:0px solid transparent!important;
  }
  .notificationButton{
    padding:10px;
  }

  h3{
    font-family: 'Fredoka One', cursive!important;
    color:#3C4858;
    color:var(--blue);
  }
}

.listMenuAccordion:before{
  height:0!important;
}

.listMenuAccordion.Mui-expanded{
  margin:0!important;
}

.listMenuAccordion .MuiAccordionSummary-root{
  padding:0!important;
  min-height: auto!important;
}

.listMenuAccordion .MuiAccordionDetails-root, .listMenuAccordion .MuiList-root{
  padding: 0!important;
  width:100%!important;
}

.listMenuAccordion .MuiAccordionSummary-content{
  margin:0!important;
}

.inputCard{
  padding: 30px;
  background-color: #fafafa;
  border-radius: 8px;
  margin-bottom:25px;
  box-shadow: 0 0 5px 1px #eaeaea;
}

.MuiChip-root{
  margin-bottom:15px!important;
}

.MuiPaper-elevation1 .MuiChip-root{
  margin-bottom:0px!important;
}

.inputCardButton{
  width:110px;
  text-align: left!important;
}

.inputCardButton .MuiButton-label{
  -webkit-justify-content: flex-start!important;
          justify-content: flex-start!important;
}

/*=========RESPONSIVE=========*/
@media screen and (max-width:960px){
.chartFilter{
  display: block!important;
} 

.chartFilter .MuiChip-root{
  margin-top:20px;
}

.notificationBox{
  display: block!important;
}

.tabContent{
  padding:15px 0!important;
  padding-bottom:50px!important;
}

.Dashboard-pageContainer-11{
  padding-bottom:50px!important;
}

.lotModal .MuiChip-root{
  margin:10px 0;
}

.lotsTable > div > div:first-child{
  display: block!important;
}

.lotsTable > div > div:first-child h6{
  margin-top:25px;
}

.lotsTable > div > div:first-child .MuiTextField-root{
  margin:15px 0;
  padding:0;
  width:100%;
}

.formPl{
  padding-left:10px;
}

.formPr{
  padding-right:10px;
}
}

@media screen and (max-width:600px){
  .formPl{
    padding-left:0px;
  }
  
  .formPr{
    padding-right:0px;
  }
}
:root {
    --smallW:2vh;
    --smallH:2vh;
    --mediumW:4vh;
    --mediumH:4vh;
    --largeW:20vh;
    --largeH:20vh;
    --dark:black;
    --light:white;
}

.small{
    width: 2vh;
    width: var(--smallW);
    height: 2vh;
    height: var(--smallH);
}

.medium{
    width: 4vh;
    width: var(--mediumW);
    height: 4vh;
    height: var(--mediumH);
}

.large{
    width: 20vh;
    width: var(--largeW);
    height: 20vh;
    height: var(--largeH);
}

.dark{
    color: black;
    color: var(--dark);
}

.light{
    color: white;
    color: var(--light);
}
.move-enter {
    opacity: 0.01;
    -webkit-transform: translate(-40px, 0);
            transform: translate(-40px, 0)
}

.move-enter-active {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    transition: all 500ms ease-in;
}

.move-exit {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
}

.move-exit-active {
    opacity: 0.01;
    -webkit-transform: translate(40px, 0);
            transform: translate(40px, 0);
    transition: all 100ms ease-in;
}
.app-class {
  padding: 0 !important;
}

#root {
  overflow: hidden;
}

.test>div{
  height:100vh;
}
.app-class {
  padding: 0 !important;
}

#root {
  overflow: hidden;
}

.test>div{
  height:100vh;
}
